.notifications {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}

.notifications .alert {
  border-width: 0 0 1px 0;
  border-radius: 0;
  opacity: 0.9;
  box-shadow: 0 0 3rem #666;
}
