@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.notifications {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}

.notifications .alert {
  border-width: 0 0 1px 0;
  border-radius: 0;
  opacity: 0.9;
  box-shadow: 0 0 3rem #666;
}

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth !important;
}

body {
  font-family: 'Inter', sans-serif;
  /*background: url('/image/bg_img.png') no-repeat top center;*/
  margin-bottom: 10rem;
  scroll-behavior: smooth !important;
}

.logo {
  float: left;
  display: block;
  font-size: 20px;
  font-weight: 200;
  line-height: 1;
  color: #ffffff;
}

body.webview #upload-from-google-drive {
  display: none;
}

main h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
}

.main-logo {
  width: 155px;
}

main h2 span {
  color: #3659b4;
}

main {
  border-radius: 45px;
  padding: 55px 20px;
  margin-bottom: 15px;
}

button:focus {
  outline: none;
}

.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  top: 0px;
  left: 0px;
  background-color: #fff;
  opacity: 0.6;
}

.spinner-overlay .spinner-border {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
}

.modal-header .close {
  color: #1633b3;
}

.modal-footer {
  background-color: #1633b3;
}

.modal-footer .btn-primary {
  background-color: #ffffff;
  color: #000000;
}

.modal-footer .btn-secondary {
  background-color: #1633b3;
  color: #ffffff;
  border: 1px solid #fff;
}

.modal-footer .btn-link {
  color: #ffffff;
}

.modal-footer .btn-link:hover,
.modal-footer .btn-link:focus {
  background-color: transparent;
}

#footer {
  height: 5rem;
  background: #ffffff;
  position: absolute;
  bottom: -1px;
  width: 100%;
}

#footer .flower {
  position: absolute;
  top: -5em;
  height: 5em;
}

#footer p {
  text-align: center;
}

.btn {
  border: none;
}

/* .btn:hover {
  background: #eff4ff;
  box-shadow: none;
} */

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-select,
.btn-select:hover,
.btn-select:focus {
  background-color: #ffffff;
}

.btn-install {
  color: #ffffff;
  background: linear-gradient(to right, #5be8aa 25%, #2ecc71 75%);
}

.btn-install:hover,
.btn-install:focus {
  color: #ffffff;
  background: linear-gradient(to right, #5be8aa 25%, #2ecc71 75%);
  box-shadow: 0px 5px 20px -5px rgba(46, 204, 113, 0.7);
}

.btn-install img {
  height: 1.5em;
}

.btn-word {
  color: #ffffff;
  background: linear-gradient(to right, #2776d6, #4faeed);
}

.btn-word:hover,
.btn-word:focus {
  color: #ffffff;
  background: linear-gradient(to right, #2776d6, #4faeed);
  box-shadow: 0px 5px 20px -5px rgba(65, 130, 215, 0.7);
}

.btn-excel {
  color: #ffffff;
  background: linear-gradient(to right, #2ecc71, #5ae8aa);
}

.btn-excel:hover {
  color: #ffffff;
  background: linear-gradient(to right, #2ecc71, #5ae8aa);
  box-shadow: 0px 5px 20px -5px rgba(46, 204, 113, 0.7);
}

.btn-powerpoint {
  color: #ffffff;
  background: linear-gradient(to right, #cf000f, #e90022);
}

.btn-powerpoint:hover {
  color: #ffffff;
  background: linear-gradient(to right, #cf000f, #e90022);
  box-shadow: 0px 5px 20px -5px rgba(207, 0, 15, 0.7);
}

.btn-jpeg {
  color: #ffffff;
  background: linear-gradient(to right, #f7ca18, #fce734);
}

.btn-jpeg:hover {
  color: #ffffff;
  background: linear-gradient(to right, #f7ca18, #fce734);
  box-shadow: 0px 5px 20px -5px rgba(255, 202, 5, 0.7);
}

.btn-download:hover,
.btn-view:hover,
.btn-download:focus,
.btn-view:focus {
  background-color: #d8ddf2;
}

.dropdown-item {
  padding: 0;
}

/* .spinner-border {
  width: 3rem;
  height: 3rem;
  border-color: #1633b3;
  border-right-color: transparent;
} */

/* .details {
  display: none;
} */

.details img {
  width: 25px;
  height: 25px;
}

.details h3 {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.view p {
  text-align: center;
}

.view-body {
  background: white;
  border-radius: 10px;
  margin: 40px 0 80px 0;
  box-shadow: 0px 0px 0px 20px rgb(208 223 255 / 22%);
  border: 1px dashed #d8e0f7;
}

/* .upload-file-view input[type='file'] {
  display: none;
} */

.upload-file-view.drag .view-body {
  background: #d8ddf2;
}

.wait-result-view {
  cursor: wait;
}

@media (min-width: 320px) and (max-width: 990px) {
  .details {
    display: none !important;
  }

  header .nav li:nth-child(1) {
    display: inline;
    display: initial;
  }

  header > div.container {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    padding: 0 15px !important;
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }

  /*
  header > div.container nav {
    position: fixed;
    background: #ffffffed;
    height: 100vh;
    width: 100vw;
    z-index: 9;
    left: 0;
    top: 0;
    max-height: 100vh;
    padding: 70px 15px;
  }

  header > div.container nav.active {
    display: block !important;
  }
*/
  .logo {
    position: relative;
    z-index: 10;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    text-align: center;
  }

  .container > div {
    /* margin: 0 auto !important; */
    max-width: 100%;
  }

  .view.select-type-view > div {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .view .col-md-4.offset-4 {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1240px) {
  .container {
    width: 1240px !important;
    max-width: 1240px;
  }
}

main {
  background: url(/static/media/left_corner.08b97b8d.png) no-repeat;
  background-color: #fbfcfd;
  position: relative;
  background-position: top left;
  background-size: 280px 380px;
}

main:after {
  content: '';
  background: url(/static/media/right_corner.3e3e47b7.png) no-repeat;
  background-size: 326px 380px;
  width: 326px;
  position: absolute;
  height: 380px;
  display: block;
  right: 0;
  bottom: 0;
  z-index: 1;
}

main .view {
  position: relative;
  z-index: 2;
}

main .choose-type {
  font-size: 20px;
  margin-bottom: 20px;
}

.sign-in.btn {
  background: #fff5f4;
  padding: 10px 35px !important;
  border-radius: 80px;
  color: #ff6b00 !important;
  border: 1px solid #ffe1de;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: all 0.5s ease;
  font-weight: 500;
}

.sign-in.btn:hover {
  transition: all 0.5s ease;
  box-shadow: 0 5px 13px 4px #f1f4f9;
}

.price-block a {
  background: #0a5dca;
  /* #0051cc; */
  padding: 15px 70px;
  border-radius: 50px;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  /* text-transform: uppercase; */
  text-decoration: none;
  display: table;
}

.price-block a:hover {
  /* filter: brightness(1.2); */
  background-color: #0654bc;
  transition: all 0.5s ease;
  box-shadow: 0 5px 13px 4px #eaedf1;
}

.price-block button {
  background: #0051cc;
  padding: 15px 70px;
  border-radius: 50px;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  display: table;
  margin-top: 20px;
}

.price-block button:hover {
  transition: all 0.5s ease;
  box-shadow: 0 5px 13px 4px #eaedf1;
}

.price-block .paypal-subscribe {
  -webkit-filter: hue-rotate(5deg) contrast(1.6) brightness(0.95) saturate(95%);
          filter: hue-rotate(5deg) contrast(1.6) brightness(0.95) saturate(95%);
}

.promo,
.install-app {
  min-height: 450px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.promo img {
  width: 90%;
}

.promo .container,
.install-app .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.promo .placement,
.promo .container .image,
.install-app .container .placement,
.install-app .container .image {
  width: 50%;
}

.promo .btn {
  color: #3659b4;
  padding: 0;
}

.promo h4,
.install-app h4 {
  font-size: 28px;
  color: black;
}

.promo p,
.install-app p {
  color: #adb4c6;
  font-size: 14px;
  line-height: 1.5;
}

.promo.promo-2 {
  background: #fbfcfd;
}

.faq-wrapper .container > h3 {
  text-align: center;
  margin-bottom: 60px !important;
  display: block;
}

.faq-wrapper li {
  list-style: none;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  padding: 25px;
  margin-bottom: 20px;
  border: 1px solid #f4f4f4;
}

.faq-wrapper li:hover {
  box-shadow: 0 20px 15px 1px #f1f4f9;
  border-color: white;
  position: relative;
  transition: all 0.5s ease;
}

.faq-wrapper li:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: url(/static/media/plus.89fe7bf6.svg) no-repeat;
  background-size: 100%;
  position: absolute;
  right: 20px;
  top: 28px;
}

.faq-wrapper li.active:after {
  background: url(/static/media/minus.2e23a4f4.svg) no-repeat;
  background-size: 100%;
}

.faq-wrapper ul {
  padding: 0;
  margin: 0;
}

.faq-wrapper h4 {
  font-size: 17px;
  margin: 0;
}

.faq-wrapper p {
  color: #1e1e1e;
  display: none;
  margin: 0;
}

.faq-wrapper .active p {
  display: block;
}

.faq-wrapper .active h4 {
  margin-bottom: 20px;
}

.faq-wrapper li.active h4 {
  color: #3659b4;
}

.conversion-options {
  background: #f6f7f9;
  padding: 20px 0;
}

.conversion-options ul {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  padding: 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.conversion-options li {
  list-style: none;
  background: white;
  border: 1px solid #cdd9f9;
  border-radius: 10px;
  font-size: 13px;
}

.conversion-options a {
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
}

.conversion-options li:first-child a,
.conversion-options li:nth-child(5) a {
  color: #325ac2 !important;
}

.install-app .image {
  background: url(/static/media/pattern.26068f06.png) no-repeat;
  border-radius: 40px;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 350px !important;
  height: 350px;
}

.install-app .image img {
  display: block;
  margin: 0 auto;
  background: white;
  padding: 30px;
  box-sizing: border-box;

  border-radius: 42px;
  box-shadow: 0 0 18px 4px #00000030;
}

.install-app .container .placement {
  width: 65%;
}

.install-list {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  width: 85%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.install-list li {
  background: white;
  border-radius: 40px;
  overflow: hidden;
  list-style: none;
  width: 120px;
  display: -webkit-inline-flex;
  display: inline-flex;
  text-align: right;
  height: 45px;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid white;
  transition: all 0.5s ease;
  box-shadow: 0 5px 13px 4px #f1f4f9;
}

.install-list li:hover {
  box-shadow: none;
  transition: all 0.5s ease;
  border: 1px solid #f1f4f9;
}

.install-list li a {
  color: black;
  margin: 0 auto;
  padding: 14px;
  font-size: 15px;
  text-decoration: none;
}

#footer .container {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 50px;
}

#footer .footer-logo img {
  width: 155px;
  margin-bottom: 10px;
}

#footer .footer-menu {
  width: 420px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: left;
}

#footer .footer-logo p {
  font-size: 13px;
  text-align: left;
  line-height: 1.4;
}

#footer .footer-menu p {
  font-size: 16px;
  color: #325ac3;
  text-align: left;
  margin-bottom: 5px;
}

#footer .footer-menu a {
  color: black;
  font-size: 15px;
  text-decoration: none;
}

#footer .footer-menu li {
  list-style: none;
}

#footer .footer-menu ul {
  padding: 0;
  margin: 0;
}

#footer .footer-help p {
  font-size: 18px;
  display: inline-block;
}

#footer .footer-help a {
  margin-top: 10px;
  background: #325ac3;
  padding: 13px 49px;
  border-radius: 47px;
  color: white;
  text-decoration: none;
  display: inline-block;
  margin-left: 26px;
  font-weight: 500;
}

.conversion-options .container ul li {
  cursor: pointer;
  height: 75px;
  transition: all 0.5s ease;
}

.conversion-options .container ul li:hover {
  transition: all 0.5s ease;
  box-shadow: 0 5px 13px 4px #f1f4f9;
  border-color: transparent !important;
}

.conversion-options .container ul li:nth-child(2),
.conversion-options .container ul li:nth-child(6) {
  border-color: #aee4b3;
}

.conversion-options .container ul li:nth-child(2) a,
.conversion-options .container ul li:nth-child(6) a {
  color: #39c332;
}

.conversion-options .container ul li:nth-child(3),
.conversion-options .container ul li:nth-child(7) {
  border-color: #f6b5aa;
}

.conversion-options .container ul li:nth-child(3) a,
.conversion-options .container ul li:nth-child(7) a {
  color: #fa8843;
}

.conversion-options .container ul li:nth-child(4),
.conversion-options .container ul li:nth-child(8) {
  border-color: #f8cb7d;
}

.conversion-options .container ul li:nth-child(4) a,
.conversion-options .container ul li:nth-child(8) a {
  color: #fac043;
}

.install-list img {
  display: inline-block;
  width: 22px;
  vertical-align: middle;
  margin-right: 5px;
}

.conversion-options img {
  width: 22px;
  margin: 0 auto 10px auto;
  display: block;
}

.ico-word,
.ico-xls,
.ico-ppt,
.ico-pdf {
  position: absolute;
  max-width: 200px;
}

.ico-pdf {
  width: 123px;
  right: 0;
  top: 200px;
  z-index: 3;
}

.ico-ppt {
  width: 120px;
  top: 37px;
  left: 129px;
}

.ico-xls {
  right: 70px;
  bottom: 55px;
  z-index: 3;
  width: 143px;
}

.burger {
  display: none;
}

.ico-word {
  left: 0;
  width: 109px;
}

.smartpdf-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 99;
  background: white;
  transition: all 0.5s ease;
}

.smartpdf-header header {
  padding: 15px 0 !important;
  transition: all 0.5s ease;
}

.logo {
  margin: 0 !important;
}

.sign-in.btn {
  margin: 0 !important;
}

.smartpdf-header.navbar-sticky header {
  padding: 10px 0 !important;
  transition: all 0.5s ease;
}

#root {
  padding-top: 82px;
}

.modal-dialog .modal-content {
  border: none;
  border-radius: 15px !important;
  overflow: hidden !important;
}

.modal-dialog .btn.btn-primary {
  background: #2ecb70;
  padding: 9px 40px !important;
  border-radius: 50px;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.modal-dialog .btn.btn-secondary {
  background: transparent;
  padding: 9px 40px !important;
  border: 1px solid #e7ecf5;
  border-radius: 50px;
  color: black;
  text-decoration: none;
  display: inline-block;
}

.modal-backdrop {
  background: #2e6dcb9c;
}

.modal-dialog .modal-footer {
  background: white;
  border-color: #e7ecf5;
}

.modal-header {
  padding: 20px 25px;
}

.modal-body {
  padding: 20px 25px;
}

.modal-footer {
  padding: 20px 25px;
}

.modal-header .close {
  color: #a0aec7;
  font-weight: 300;
  opacity: 1;
  font-size: 30px;
}

.modal-title {
  padding-top: 2px;
}

.modal-footer .btn-link {
  color: black;
}

.modal-dialog input[type='text'],
.modal-dialog input[type='email'],
.modal-dialog input[type='password'] {
  border-color: #e7ecf5 !important;
  padding: 15px !important;
  height: 50px !important;
  max-height: 50px !important;
}

.pricing {
  margin: 0 !important;
  padding: 0 !important;
}

.pricing > .heading {
  background: url(/static/media/pricing_bg.cf7100f2.jpg) no-repeat;
  background-size: cover;
  background-color: #0051cc;
  padding: 50px 0;
  color: white;
}

.pricing .heading h2 {
  color: white;
  text-align: center;
}

.pricing .heading img {
  width: 50px;
  margin: 0 auto;
  display: block;
}

.pricing .heading p {
  text-align: center;
}

.pricing .comparizon {
  border: 1px solid #e8eaef;
  border-radius: 20px;
  /*box-shadow: 0px 4px 4px -2px #f0f5ff;*/
  padding: 20px;
}

.pricing .price-block {
  border: 1px solid #e8eaef;
  border-radius: 12px;
  width: 100%;
  margin: 0 !important;
  position: relative;
  min-height: 220px;

  background: white !important;
  /*box-shadow: 0px 4px 4px -2px #f0f5ff;*/
  padding: 20px !important;
}

.pricing .price-block-active {
  border-color: #13cd9a;
}

.pricing .price-block:first-child {
  margin: 0 0 15px 0 !important;
}

h2 i {
  font-size: 12px;
  font-style: normal;
  background: #13cd9a;
  color: white;
  border-radius: 17px;
  padding: 5px 11px;
  vertical-align: middle;
  margin-left: 7px;
}

.pricing h3 {
  margin: 0 !important;
}

.pricing .table-content {
  width: 100%;
}

.pricing table {
  width: 100%;
  font-size: 14px;
}

.pricing table img {
  width: 18px;
  height: 18px;
}

table thead th,
.table td,
.table th {
  border: none !important;
}

table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3) {
  text-align: center;
}

table thead tr th:nth-child(2),
table thead tr th:nth-child(3) {
  text-align: center;
}

table tbody tr:nth-child(2n) {
  background: #f6f7f9;
}

.pricing .faq-wrapper {
  margin-bottom: 90px;
}

.navbar-sticky header {
  box-shadow: 0px 4px 19px rgb(220 226 255 / 25%);
}

header .nav a {
  color: black;
  font-weight: 500;
  transition: all 0.5s ease;
}

header .nav a:hover,
header .nav a.active {
  color: #315ac3;
  transition: all 0.5s ease;
}

.sub-price {
  font-weight: bold;
  color: #13cd9a;
  font-size: 47px;
}

.sub-period {
  text-transform: uppercase;
  font-size: 24px;
}

.pricing .container {
  -webkit-justify-content: center;
          justify-content: center;
}

.sub-price:first-letter {
  font-weight: 400;
  margin-right: 5px;
}

.inner-text h3 {
  height: 150px;
  background: #f6f7f9;
  text-align: center;
  padding: 57px 0;
  color: black;
  margin-bottom: 65px !important;
}

.inner-text .container {
  margin-bottom: 65px;
}

.inner-text p {
  line-height: 1.7;
}

.faq-wrapper h3 {
  text-align: center;
  margin-bottom: 65px;
}

.user-nav span {
  background: #315ac3;
  color: white;
  border-radius: 50px;
  font-size: 28px;
  width: 40px;
  height: 40px;
  line-height: 1.1;
  margin-right: 13px;
  vertical-align: middle;
  text-indent: 1px;
  padding: 5px;
  display: inline-block;
  text-align: center;
}

.page {
  padding-bottom: 70px;
}

.user-nav i {
  font-style: normal;
}

.dropdown-toggle:after {
  vertical-align: 3px;
}

.dropdown-menu {
  border-color: #f6f7f9;
  margin-top: 1px;
  box-shadow: 0px 9px 12px 0px #3b386b14;
  padding: 0;
  font-size: 15px;
}

.dropdown-menu button,
.user-nav .dropdown-menu a {
  font-size: 15px;
  padding-left: 15px !important;
}

.user-menu {
  margin-bottom: 40px;
  padding: 20px 0 10px 0;
  box-shadow: 0px 11px 8px 0px #fbfbfb;
}

.user-menu ul {
  -webkit-justify-content: center;
          justify-content: center;
}

.user-menu img {
  width: 37px;
  display: block;
  margin: 0 auto 10px auto;
}

.user-menu ul li:first-child img {
  width: 27px;
  margin-bottom: 12px;
}

.user-menu li {
  padding: 0;
  margin-right: 40px;
  display: -webkit-flex;
  display: flex;
  border: 1px solid transparent !important;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 10px !important;
}

.dropdown-item {
  padding: 7px 15px !important;
}

.user-menu li:last-child {
  margin-right: 0;
}

.user-menu li:hover {
  background: #fbfbfb;
  border-color: #ececec !important;
}

.inner-text {
  color: #485167;
  font-size: 15px;
}

.user-menu ul li:nth-child(3) img {
  width: 32px;
}

.user-menu ul li:nth-child(2) img {
  width: 34px;
}

.user-menu a {
  display: table;
  text-decoration: none;
  color: #315ac3;
  min-width: 150px;
  padding: 0.75rem 1.25rem;
  text-align: center;
}

.user-page form {
  background: #fbfbfb;
  padding: 75px;
  border-radius: 30px;
  max-width: 800px;
  margin: 32px auto 100px auto;
}

.select-file {
  margin-top: 10px;
  background: #325ac3 !important;
  padding: 13px 49px;
  border-radius: 47px;
  color: white !important;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
}

.try-again {
  margin-top: 10px;
  background: #325ac3 !important;
  padding: 13px 49px;
  border-radius: 47px;
  color: white !important;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
}

.quick-switch button {
  font-size: 16px;
  padding: 10px 19px;
  border: 1px solid #e7ebf9;
}

.quick-switch strong {
  margin-top: 3px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #315ac3;
}

.user-page h3 {
  text-align: center;
}

.user-page p {
  text-align: center;
}

.user-page .user-menu {
  padding: 10px 0;
}

.user-page .form-control {
  border: none;
  background: white;
  border-radius: 10px;
  padding: 25px 15px;
  box-shadow: 0px 5px 10px 1px #0000000a;
}

.user-page form button {
  margin: 10px auto 0 auto;
  display: block;
  background: #325ac3;
  padding: 13px 49px;
  border-radius: 47px;
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.user-page form p {
  text-align: left;
  font-size: 15px;
  color: #272e54;
}

.pricing .faq-wrapper h3 {
  margin-bottom: 65px !important;
}

.clean-history img {
  max-width: 700px;
  margin: 0 auto;
  display: block;
  opacity: 0.6;
}

.clean-history h3 {
  text-align: center;
  color: #000000;
}

.paginator {
  display: table;
  margin: 30px auto;
}

.page {
  min-height: calc(100vh - 420px);
}

.conversion-options {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 115px;
}

.premium-banner {
  background: url(/static/media/pricing_bg.cf7100f2.jpg) no-repeat;
  background-size: cover;
  background-color: #0051cc;
  padding: 25px 0 10px 0;
  color: white;
}

.premium-banner .container {
  padding: 0 !important;
  margin: 0 auto !important;
}

.premium-banner .btn {
  border-radius: 47px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500 !important;
  padding: 10px 80px !important;
  display: inline-block;
}

.details-img img {
  width: 30px;
  height: auto;
}

.details-img {
  padding: 19px;
  background: white;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px dashed #bec7de;
}

.details-item h4 {
  font-size: 18px;
  text-align: center;
}

.details .details-item:nth-child(2),
.details .details-item:nth-child(3),
.details .details-item:nth-child(5) {
  border-left: 1px dashed #d3dae2;
}

.details .details-item:nth-child(1),
.details .details-item:nth-child(2),
.details .details-item:nth-child(3) {
  border-bottom: 1px dashed #d3dae2;
}

.details .details-item:nth-child(4),
.details .details-item:nth-child(5) {
  padding-top: 25px;
}

.details-item p {
  font-size: 13px;
}

.details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.details .details-item {
  width: 33%;
  padding: 10px 27px;
}

.details p:last-child {
  text-align: justify;
}

.contacts .col-md-6,
.contacts .col-md-12 {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0;
}

.contacts .col-md-12.d-flex {
  min-height: 600px;
}

.contacts form input[type='text'],
.contacts form input[type='email'],
.contacts form textarea {
  background: transparent;
  box-shadow: none;
  resize: none;
  outline: none !important;

  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  padding: 15px 0;
  margin-bottom: 20px;
}

.contacts .form-row .col-md-6 {
  width: 49% !important;
  max-width: 49% !important;
}

.contacts .form-row {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.contacts form button[type='submit'] {
  margin-top: 10px;
  background: #325ac3;
  padding: 13px 49px;
  border-radius: 47px;
  color: white;
  text-decoration: none;
  display: inline-block;
  margin-left: -4px;
  font-weight: 500;
}

.contacts .container > .col-md-12 .col-md-6:last-child {
  padding-left: 75px;
}

.contacts .container > .col-md-12 .col-md-6:last-child > div {
  padding-left: 75px;
  border-left: 1px solid #e6eaf1;
}

.contacts h4 {
  color: black;
  font-size: 46px;
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
}

.ribbon-primary:before,
.ribbon-primary:after {
  border: 3px solid #1eb5ff;
}

.ribbon-success:before,
.ribbon-success:after {
  border: 3px solid #1effb5;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 177px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0;
  padding-right: 14px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.ribbon-primary span {
  background-color: #315ac1;
}

.ribbon-success span {
  background-color: #13cd9a;
}

/* top left*/
.ribbon-top-left {
  top: -3px;
  left: -3px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -22px;
  top: 30px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -3px;
  right: -3px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -22px;
  top: 30px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.user-page .price-block {
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
}

.user-page .price-block .sub-price {
  text-align: left;
}

.text-green {
  color: #13cd9a;
}

.subscription-page table,
.subscription-page a {
  margin: 0 auto;
}

.subscription-page table {
  width: 87%;
  margin-bottom: 40px;
}

.subscription-page {
  background: #fbfbfb;
  border: 2px dashed #f6f7f9;
  border-radius: 30px;
  padding: 50px 20px;
  max-width: 800px;
  margin: 32px auto 100px auto;
}

.subscription-page table tbody tr:nth-child(2n) {
  background: #efefef;
}

.subscription-page .sub-period {
  font-weight: bold;
}

ul.nav > div {
  display: -webkit-flex;
  display: flex;
}

ul.nav > div > li > a {
  padding-top: 13px !important;
}

.subscription-page .ribbon span {
  background-color: #13cd9a;
  padding-right: 17px;
}

.subscription-page p {
  color: #848b98;
  font-weight: 500;
}

.mobile-type {
  display: none;
}

.faq-wrapper p {
  margin: 10px 0;
}
.adsbygoogle {
  margin: 0 auto 30px auto;
  display: block !important;
}

@media (max-width: 720px) {
  #root .adsbygoogle {
    max-width: calc(100% - 20px) !important;
    overflow: hidden;
    width: calc(100% - 20px) !important;
    margin: 0 auto 30px auto !important;
    height: 190px !important;
  }
}

@media (max-width: 1023px) {
  ul.nav {
    display: none;
    position: fixed;
    padding: 70px 14px 25px 5px;
    width: 100%;
    background: white;
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.09);
    z-index: 9;
    left: 0;
    top: 0;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .smartpdf-header header {
    padding: 30px 0 15px 0 !important;
  }

  .smartpdf-header.navbar-sticky header {
    padding: 30px 0 15px 0 !important;
    transition: all 0.5s ease;
  }

  .sign-in.btn {
    border: none;
    padding: 0 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    color: black;
    padding-top: 10px !important;
  }

  ul.nav > div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .burger * {
    margin: 0;
    padding: 0;
  }

  nav {
    overflow: visible !important;
  }

  .burger {
    overflow: visible;
    width: 30px;
    position: relative;
    height: 25px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  header > div.container {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .burger span:nth-child(1) {
    top: 0px;
  }

  .burger span:nth-child(2),
  .burger span:nth-child(3) {
    top: 8.5px;
  }

  .burger span:nth-child(4) {
    top: 18px;
  }

  .burger.open span:nth-child(1) {
    top: 8.5px;
    width: 0%;
    left: 50%;
  }

  .burger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .burger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .burger.open span:nth-child(4) {
    top: 8.5px;
    width: 0%;
    left: 50%;
  }

  .burger.open span:nth-child(2) {
    left: 0px;
    top: 10px;
  }

  ul.nav a {
    font-size: 18px;
  }

  @-webkit-keyframes slideInTop {
    from {
      top: -380px;
    }
    to {
      top: 0px;
    }
  }

  @keyframes slideInTop {
    from {
      top: -380px;
    }
    to {
      top: 0px;
    }
  }

  .burger {
    position: relative;
    z-index: 999;
    display: block;
  }

  ul.active {
    display: -webkit-flex;
    display: flex;
    -webkit-animation: slideInTop 0.5s forwards;
            animation: slideInTop 0.5s forwards;
  }

  .smartpdf-header {
    opacity: 1;
  }

  .page main > img {
    display: none;
  }

  .promo.promo-2 {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
    margin-bottom: 0;
  }

  .promo-3 .image {
    margin-bottom: 0;
  }

  .install-app .image {
    width: 90vw !important;
    height: 90vw !important;
    margin: 0 auto 30px auto !important;
  }

  .page {
    padding-bottom: 370px;
  }

  .view .col-md-6 {
    padding: 0;
  }

  .faq-wrapper li {
    padding-right: 40px;
  }

  .mobile-type {
    display: block;
    margin: 10px auto 20px auto;
  }

  .user-menu li {
    width: 33% !important;
  }

  .price-block a {
    margin: 20px auto;
  }

  .view .col-md-6 .btn {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .view .col-md-6 {
    width: 100% !important;
  }

  .view .col-md-10 {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .choose-type {
    display: none;
  }

  main {
    padding: 20px;
  }

  main h2 {
    font-size: 24px;
  }

  .promo .container,
  .install-app .container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
  }

  .promo {
    margin-bottom: 50px;
  }

  .promo .image,
  .install-app .image {
    margin-bottom: 30px !important;
  }

  .promo .placement,
  .promo .container .image,
  .install-app .container .placement,
  .install-app .container .image {
    width: 100%;
  }

  .install-list {
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .install-list li {
    width: calc(50% - 10px);
    margin-bottom: 20px !important;
    margin-right: 20px !important;
  }

  .install-list li:nth-child(2n) {
    margin-right: 0 !important;
  }

  .conversion-options ul {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .conversion-options ul li {
    width: calc(50% - 10px);
    margin-bottom: 20px !important;
    text-align: center;
    margin-right: 20px !important;
  }

  .conversion-options ul li:nth-child(2n) {
    margin-right: 0 !important;
  }

  #footer .container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .footer-help {
    zoom: 0.9;
  }

  .contacts .col-md-12.d-flex {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .contacts .container > .col-md-12 .col-md-6:last-child {
    padding: 0;
    border: none;
  }

  .contacts .container > .col-md-12 .col-md-6:last-child > div {
    border: none;
    padding: 0;
  }

  .user-menu .list-group {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .user-menu li {
    margin: 0;
  }

  .user-page form {
    padding: 40px;
  }

  .pricing .container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .sub-period i {
    display: none;
  }

  .price-block {
    text-align: center;
  }
}

@media (min-width: 767px) and (max-width: 1025px) {
  .view .col-md-6 .btn {
    width: auto;
  }

  .mobile-type {
    display: none;
  }

  .choose-type {
    display: block !important;
  }

  .promo .container,
  .install-app .container {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
  }

  .promo-1 .container,
  .promo-3 .container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .promo {
    padding: 20px 0 !important;
    text-align: center;
  }

  .promo img {
    width: 55%;
    margin: 0 auto 20px auto;
    display: table;
  }

  .promo .image {
    width: 100%;
  }

  .install-app .container > img {
    width: 150px !important;
    height: 150px !important;
  }

  .install-app .container .image {
    max-width: 250px !important;
    max-height: 250px !important;
    margin-bottom: 20px;
  }

  #footer .footer-help a {
    padding: 13px 30px;
  }

  .select-file {
    padding: 19px 30px;
  }

  #footer .footer-menu {
    margin-bottom: 30px !important;
  }

  .conversion-options ul {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .conversion-options li {
    width: 24%;
    margin-right: 1%;
    margin-bottom: 1%;
    text-align: center;
  }

  .install-app .container {
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }

  .container > .user-nav {
    margin: 0 !important;
  }

  .subscription-page {
    -webkit-flex: 0 0 80%;
            flex: 0 0 80%;
  }

  .install-list {
    width: 100%;
    padding-bottom: 30px;
  }

  .premium-banner h3 span {
    font-size: 36px;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) {
  .premium-banner {
    padding-bottom: 100px;
  }

  .premium-banner h3 span {
    font-size: 28px;
  }
}

.social-icons img {
  width: 30px !important;
  opacity: 0.5;
}

.social-icons img:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.social-icons a {
  margin-right: 5px;
}

.main-premium-banner {
  margin: 0 auto 20px auto;
  display: table;
  max-width: 650px;
  overflow: hidden;
  border-radius: 10px;
}

.main-premium-banner img {
  max-width: 100%;
}

